<template>
    <div>
        <app-template>
            <img class="h-28 mx-auto" src="https://img.freepik.com/icones-gratuites/android_318-674214.jpg" alt="android logo">
            <div class="text-center">
                <div class="mt-7">
                    <a href="/apps/inventaire.apk" target="_blank" class="bg-green-600 text-white px-6 py-2 rounded">
                        Télécharger l'application <i class="fas fa-download ml-1"></i>
                    </a>
                </div>

                <h2 class="mt-10 font-2xl font-bold">Comment installer l'application ?</h2>
                <div class="text-left border-t border-gray-300 mt-5 pt-5">
                    <ul class="list-decimal ml-4 pl-4">
                        <li class="mb-10">
                            Cliquez sur le bouton "Télécharger l'application" ci-dessus
                        </li>
                        <li class="mb-10">
                            <img class="w-full border-2 rounded" src="/tutos/1.jpeg" />
                        </li>
                        <li class="mb-10">
                            <img class="w-full border-2 rounded" src="/tutos/2.jpg" />
                        </li>
                        <li class="mb-10">
                            <img class="w-full border-2 rounded" src="/tutos/3.jpg" />
                        </li>
                        <li class="mb-10">
                            <img class="w-full border-2 rounded" src="/tutos/4.jpg" />
                        </li>
                        <li class="mb-10">
                            Une fois l'application installée, vous pouvez vous connecter
                        </li>
                        <li class="mb-10">
                            Une fois connecté, pensez bien à autoriser l'accès à l'appareil photo et au microphone
                        </li>
                    </ul>

                    <div class="mt-10 text-sm border-t border-gray-300 mt-5 py-5">Pour toutes questions, vous pouvez nous contacter à : <b>contact@savlog.net</b></div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>