import myaxios from './axios.service'

function convertObjToFormData(keyname, obj)
{
    var formData = new FormData();

    for (var key in obj) {
        formData.append(key, obj[key]);
    }

    formData.append('action', keyname)

    if (localStorage['account-api']) {
        let accout = JSON.parse(localStorage.getItem('account-api'))
        if (!obj.id) {
            formData.append('id', accout.id)
        }
        formData.append('token', accout.token)
    }

    return formData
}

const ApiService = {
    post: async function (keyname, obj, options = null) {
        return await myaxios.post('', convertObjToFormData(keyname, obj), options)
    },
}
export default ApiService
