import Vue from 'vue'
import VueRouter from 'vue-router'

import Inventory from '../views/Inventory.vue'
import Login from '../views/Login.vue'
import DownloadApk from '../views/DownloadApk.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'inventory', component: Inventory},
  {path: '/connexion', name: 'login', component: Login},
  {path: '/download/apk', name: 'download.apk', component: DownloadApk},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
