<template>
    <div>
        <div v-if="displayModal" class="p-5 fixed top-0 left-0 right-0 bottom-0 bg-black/80 z-20 flex items-center">
            <div class="text-2xl font-semibold text-center bg-white p-5 grow rounded">
                Confirmez-vous la validation de cet emplacement ?
                <div class="py-2 flex items-center gap-2 text-lg justify-center mt-4 font-semibold">
                    <button @click="displayModal = false" class="bg-slate-300 px-5 py-2 rounded">
                        Annuler <i class="fas fa-times ml-0.5"></i>
                    </button>
                    <button @click="end" class="bg-green-300 text-green-900 px-6 py-2 rounded">
                        Valider <i class="fas fa-check ml-0.5"></i>
                    </button>
                </div>
            </div>
        </div>

        <app-template :key="test">
            <template v-if="!pageType">
                <h2 class="text-4xl font-semibold text-center mt-6 mb-10" v-if="!pageType">Séléctionnez votre type d'inventaire</h2>
                <div class="rounded-full grid grid-cols-2 gap-2 mb-6 bg-white border">
                    <div @click="pageType = 'pieces'" class="p-3 text-center" :class="(pageType == 'pieces' ? 'font-semibold bg-violet-300 border-violet-400 rounded-full text-violet-900' : '') + ' ' + (!pageType ? 'border-r' : '')">
                        <i class="fas fa-layer-group mr-1"></i> Pièces
                    </div>
                    <div @click="pageType = 'produits'" class="p-3 text-center" :class="pageType == 'produits' ? 'font-semibold bg-violet-300 border-violet-400 rounded-full text-violet-900' : ''">
                        <i class="fas fa-clock-rotate-left mr-1"></i> Produits
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex items-center justify-between mb-6">
                    <span class="capitalize text-2xl text-slate-800 font-black">{{ pageType }}</span>
                    <div class="text-right">
                        <button @click="pageType = null" class="bg-slate-300 px-4 py-2 rounded">
                            <i class="fas fa-home mr-1"></i> Accueil
                        </button>
                    </div>
                </div>
            </template>

            <template v-if="displayPage && pageType">
                <template v-if="!code">
                    <div class="mb-4">
                        <div class="flex items-center">
                            <input v-model="inputSearch" type="text" id="username" placeholder="Saisir le code emplacement" class="bg-gray-50 border border-gray-300 border-r-0 text-gray-900 text-sm rounded-l-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <button :disabled="loadingSearch" @click="manualSearch" class="bg-green-400 border border-green-400 px-4 py-2 rounded-r-xl">
                                <div v-if="!loadingSearch">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div v-if="loadingSearch">
                                    ...
                                </div>
                            </button>
                        </div>
                    </div>
                    
                    <h2 class="text-xl font-bold my-4 text-center">Scannez le qrcode de votre emplacement</h2>
                    <read-barcode @decode="onDecode"></read-barcode>
                </template>

                <template v-if="code">
                    <div class="flex items-center justify-between mb-3 p-4 bg-slate-500 rounded">
                        <h1 class="text-xl font-black text-white">{{ code }}</h1>
                        <button @click="initNewSpace" class="bg-slate-200 px-4 py-2 rounded">
                            <i class="fas fa-barcode"></i>
                        </button>
                    </div>
                    <div v-for="(item, itemIndex) in items" :key="'item-' + itemIndex">
                        <div class="bg-white rounded p-6 flex items-center gap-2 justify-between mb-3">
                            <div>
                                <div class="font-bold">{{ item.reference }}</div>
                                <div class="font-light text-sm">{{ item.designation }}</div>
                            </div>
                            <div v-if="item.found">
                                <div class="flex gap-3">
                                    <button v-if="canEditLine(item)" @click="updateQtt(item, 'sub')" class="bg-red-200 rounded px-4 py-2">
                                        <template v-if="item.qte == 0 && item.qte !== null">
                                            <i class="fas fa-trash text-xs"></i>
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-minus text-xs"></i>
                                        </template>
                                    </button>
                                    <div class="px-4 py-2 bg-slate-200 rounded font-bold">{{ item.qte }}</div>
                                    <button v-if="canEditLine(item)" @click="updateQtt(item, 'add')" class="bg-green-200 rounded px-4 py-2">
                                        <i class="fas fa-plus text-xs"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <button @click="updateQtt(item, 'init')" class="bg-green-200 rounded px-4 py-2">
                                    Pointer <i class="fas fa-check ml-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="fixed bottom-0 left-0 right-0 bg-slate-100 py-2 px-3">
                        <button @click="goDisplayModal" class="bg-neutral-500 text-white rounded font-bold w-full px-6 py-4">Clotûrer <i class="fas fa-lock ml-1"></i></button>
                    </div>
                    <!-- Offset -->
                    <div class="h-[60px]"></div>
                </template>
            </template>
        </app-template>
    </div>
</template>

<script>
import ApiService from '../../services/api.service'
export default {
    watch: {
        pageType() {
            this.initNewSpace()
        }
    },

    data() {
        return {
            code: null,
            pageType: null,
            displayPage: true,
            items: [],

            inputSearch: null,
            loadingSearch: false,

            codeErrors: [],

            displayModal: false,

            test: 0,
        }
    },

    computed: {
        onLocalhost() {
            return (location.hostname === "localhost" || location.hostname === "127.0.0.1")
        },
    },

    methods: {
        initNewSpace() {
            this.items = []
            this.code = null
            this.displayPage = false
            this.inputSearch = null
            this.codeErrors = []
            this.$nextTick(() => {
                this.displayPage = true
            })
        },
        async onDecode(result) {
            if (this.codeErrors.find((code) => code == result)) {
                return
            }
            let operation = (this.pageType == 'pieces' ? 'getpiece_inv' : 'getpiece_inv_produit')
            let response = await ApiService.post(operation, {
                id_user: JSON.parse(localStorage.getItem('account-api')).id_user,
                id_grossiste: JSON.parse(localStorage.getItem('account-api')).id,
                stockeur: result
            })
            if (response.data.posts.post.length <= 0) {
                this.inputSearch = null
                this.codeErrors.push(result)
                this.$toast.error('Emplacement introuvable')
                return
            }
            if (response.data.posts.post[0].date_inventaire) {
                this.inputSearch = null
                this.codeErrors.push(result)
                this.$toast.error('Emplacement clotûré')
                return
            }
            this.code = result
            this.items = response.data.posts.post.map((item) => ({...item, found: (item.qte == null ? false : true)}))
        },

        async manualSearch() {
            this.loadingSearch = true
            await this.onDecode(this.inputSearch)
            this.loadingSearch = false
        },

        async updateQtt(item, type) {
            if (type == 'sub') {
                if (item.qte - 1 >= 0) {
                    item.qte--
                } else {
                    item.found = 0
                    item.qte = null
                }
            } else if (type == 'add') {
                item.qte++
            } else if (type == 'init') {
                item.found = true
                item.qte = 0
            }

            let endObj = {
                id_user: JSON.parse(localStorage.getItem('account-api')).id_user,
                id_grossiste: JSON.parse(localStorage.getItem('account-api')).id,
                id: item.id,
            }
            if (item.qte !== null) {
                endObj.qte = item.qte
            }

            let operation = (this.pageType == 'pieces' ? 'updateqte_inv' : 'updateqte_inv_produit')
            await ApiService.post(operation, endObj)
        },

        async end() {
            let operation = (this.pageType == 'pieces' ? 'validstockeur_inv' : 'validstockeur_inv_produit')
            let response = await ApiService.post(operation, {
                id_user: JSON.parse(localStorage.getItem('account-api')).id_user,
                id_grossiste: JSON.parse(localStorage.getItem('account-api')).id,
                id_tech: 0,
                stockeur: this.code
            })
            if (response.data.posts.post == 'ok') {
                this.displayModal = false
                this.$toast.success('Enregistré')
                this.initNewSpace()
            }
        },

        goDisplayModal() {
            let error = false
            this.items.forEach((item) => {
                if (!item.found && this.pageType == 'pieces') {
                    error = true
                }
            })
            if (error) {
                this.$toast.error("Merci de pointer toutes vos pièces")
                return
            }

            this.displayModal = true
        },
 
        canEditLine(item) {
            return !item.date_inventaire
        }
    },

    mounted () {
        if (!localStorage.getItem('inventaire-account')) {
            this.$router.push({name: 'login'})
            return
        }
    },
}
</script>
