<template>
    <div>
        <div class="xl:hidden">
            <div class="fixed top-0 left-0 right-0 py-4 px-3 border-b shadow bg-white">
                <div class="flex justify-between items-center">
                    <img :src="logo" class="h-8" alt="Logo">
                    <div @click="logout" class="font-semibold text-sm text-center">
                        {{ headerLabel }}
                        <template v-if="account">
                            <i class="fa-solid fa-right-from-bracket ml-1"></i>
                        </template>
                    </div>
                </div>
            </div>
            <div class="p-3 pt-[80px]">
                <slot></slot>
            </div>
        </div>
        <div class="hidden xl:flex h-screen items-center">
            <h1 class="text-4xl font-black text-gray-800 w-1/2 text-center mx-auto">Application accessible uniquement sur smartphone ou tablette</h1>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            account() {
                if (localStorage.getItem('inventaire-account')) {
                    return JSON.parse(localStorage.getItem('inventaire-account'))
                }
            },
            headerLabel() {
                if (this.account) {
                    return this.account.username
                }
                return "Gestion de l'inventaire"
            },
            logo() {
                let logo = localStorage.getItem('inventaire-logo')
                return logo ? logo : 'https://i.ibb.co/ThVCB2L/savlog-1-1.png'
            }
        },

        methods: {
            logout() {
                if (this.account) {
                    localStorage.removeItem('inventaire-account')
                    localStorage.removeItem('inventaire-logo')
                    this.$router.push({name: 'login'})
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>