import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Packages
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {});

// Components
import AppTemplate from '@/components/AppTemplate.vue'
Vue.component('app-template', AppTemplate)

import { ImageBarcodeReader } from "vue-barcode-reader";
Vue.component('barcode', ImageBarcodeReader)

import { StreamBarcodeReader } from "vue-barcode-reader";
Vue.component('read-barcode', StreamBarcodeReader)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
