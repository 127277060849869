<template>
    <div>
        <app-template>
            <div class="mb-4">
                <label for="username" class="block mb-1 text-sm font-medium text-gray-900">Utilisateur</label>
                <input autocorrect="off" autocapitalize="off" v-model="form.username" type="text" id="username" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            </div>
            <div class="mb-4">
                <label for="password" class="block mb-1 text-sm font-medium text-gray-900">Mot de passe</label>
                <input autocorrect="off" autocapitalize="off" v-model="form.password" type="text" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            </div>
            <div class="mb-4">
                <label for="station" class="block mb-1 text-sm font-medium text-gray-900">Adresse de la station</label>
                <input autocorrect="off" autocapitalize="off" v-model="form.station" type="text" id="station" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            </div>
            <button @click="connect" class="bg-blue-400 rounded font-bold px-6 py-3 text-white">Se connecter <i class="fas fa-chevron-right ml-1"></i></button>
        </app-template>
    </div>
</template>

<script>
import apiService from '/services/api.service'

export default {
    data() {
        return {
            form: {}
        }
    },
    methods: {
        async connect() {
            if (!this.form.username || !this.form.password || !this.form.station) {
                this.$toast.error('Formumaire incomplet')
                return
            }
            localStorage.setItem('temp-form-login-inventaire', JSON.stringify(this.form))

            let response = await apiService.post('A5141login', {
                user: this.form.username,
                pwd: this.form.password,
                domaine: 'www.districloud.net'
            })

            if (response.data.posts.error == "AUTH_FAILED") {
                this.$toast.error('Identifiants incorrects')
            } else if (response.data.posts.post && response.data.posts.post.length > 0) {
                localStorage.setItem('inventaire-account', JSON.stringify(this.form))
                localStorage.setItem('inventaire-logo', response.data.posts.options.logo)
                localStorage.setItem('account-api', JSON.stringify({
                    id: response.data.posts.post[0].id_entite,
                    id_user: response.data.posts.post[0].id,
                    token: response.data.posts.post[0].token,
                }))
                window.location = '/'
            }
        }
    },

    mounted () {
        if (localStorage.getItem('temp-form-login-inventaire')) {
            this.form = JSON.parse(localStorage.getItem('temp-form-login-inventaire'))
        }
    },
}
</script>
